@import "~bootstrap/scss/functions"
@import "~bootstrap/scss/variables"
@import "~bootstrap/scss/mixins/breakpoints"

.home-carousel-container
  width: 100%
  position: relative

  .slick-slide img
    width: 100%

  .slick-dots
    bottom: 20px
    +media-breakpoint-up(sm)
      bottom: 30px
    li button:before
      font-size: 10px
      line-height: 20px
      color: #fff
      opacity: 0.60
    li.slick-active button:before
      opacity: 1

  .slide-container
    position: relative

  .slide-overlay
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    +media-breakpoint-up(sm)
      background-color: rgba(0, 0, 0, 0.1)

  .home-carousel-text
    font-family: Allura
    width: 100%
    text-align: center
    color: #fff
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-weight: 800
    h1
      text-shadow: 0 1px 2px rgba(0,0,0,0.65), 0 2px 16px rgba(0,0,0,0.48)
      font-size: 70px
      line-height: 60px
      +media-breakpoint-up(sm)
        font-size: 130px
        line-height: 200px
