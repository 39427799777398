@import "~bootstrap/scss/functions"
@import "~bootstrap/scss/variables"
@import "~bootstrap/scss/mixins/breakpoints"

$mobile-height: 300px
$desktop-height: 400px

.home-testimonials-carousel-container
  height: $mobile-height
  +media-breakpoint-up(sm)
    height: $desktop-height
  width: 100%
  background-image: url("./images/background.jpg")

  .slide-container
    position: relative
    height: $mobile-height
    +media-breakpoint-up(sm)
      height: $desktop-height
    background-color: rgba(0, 0, 0, 0.1)

  .slide-content

    text-align: center
    color: #fff
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-weight: 800
    width: 80%
    +media-breakpoint-up(sm)
      width: 50%
    h1
      text-shadow: 0 1px 2px rgba(0,0,0,0.65), 0 2px 16px rgba(0,0,0,0.48)
      font-size: 25px
      +media-breakpoint-up(sm)
        font-size: 50px
    h2
      text-shadow: 0 1px 2px rgba(0,0,0,0.65), 0 2px 16px rgba(0,0,0,0.48)
      font-size: 20px
      +media-breakpoint-up(sm)
        font-size: 25px
