@import "~bootstrap/scss/functions"
@import "~bootstrap/scss/variables"
@import "~bootstrap/scss/mixins/breakpoints"

.home-factory-carousel-container
  position: relative
  .slick-arrow
    &.slick-prev:before, &.slick-next:before
      font-size: 30px
      opacity: 1
    &.slick-prev
      left: 10px
      z-index: 10
    &.slick-next
      right: 20px
