@mixin sticky-footer($footer-height, $wrapper-selector: unquote("#wrapper"), $container-selector: unquote("#container"), $footer-selector: unquote("#footer"))
  html, body
    height: 100%
  body
    margin: 0px
    padding: 0px
  #{$wrapper-selector}
    min-height: 100%
    height: auto !important
    height: 100%
    margin-bottom: -$footer-height
    #{$container-selector}
      padding: 0 0 $footer-height 0
  #{$footer-selector}
    height: $footer-height
