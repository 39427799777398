.party-section
  padding: 80px 0
  &.darken
    background-color: rgba(43, 43, 43, .9)
    color: #FFF

.party-section-title
  margin-bottom: 30px
  margin-top: 10px
  text-align: center
  font-size: 30px
  +media-breakpoint-up(sm)
    font-size: 45px
    margin-bottom: 70px
    margin-top: 30px

.party-section-description
  margin: 15px 0 0 0
  text-align: center
  font-size: 18px
  margin-bottom: 30px
  +media-breakpoint-up(sm)
    margin-bottom: 0
