// Font sizes
$font-family-base: 'Noticia Text', serif
$font-size-base: 1rem
$h1-font-size:   $font-size-base * 2.3
$h2-font-size:   $font-size-base * 1.9

//Margins
$headings-margin-bottom: 20px

// Global border radius
$border-radius:    2px
$border-radius-lg: 2px
$border-radius-sm: 2px

//Navbar
$navbar-padding-x:          25px
$navbar-padding-y:          26px
$navbar-dark-color:         rgba(#fff,.8)
$navbar-dark-hover-color:   #b6a083
$navbar-dark-active-color:  #b6a083
$navbar-brand-font-size:    1.5rem
$navbar-nav-link-padding-x: 20px


@import "~bootstrap/scss/bootstrap"
