+media-breakpoint-up(sm)
  +sticky-footer(177px, "#app-root", "#page-container", "footer")

body
  -webkit-font-smoothing: antialiased
  padding-top: 92px
  +media-breakpoint-up(sm)
    padding-top: 0

.navbar-dark .navbar-brand
  color: #fff
  font-size: 1.1rem
  +media-breakpoint-up(sm)
    font-size: 1.5rem


.bg-custom-dark
  background-color: rgb(43, 43, 43)
  +media-breakpoint-up(sm)
    background-color: rgba(43, 43, 43, 0.9)

.padded-top-container
  padding-top: 20px
  +media-breakpoint-up(sm)
    padding-top: 140px

.app-table
  width: 100%
  th, td
    padding: 4px
    +media-breakpoint-up(sm)
      padding: 8px

footer
  color: #fff
  background-color: rgb(47, 47, 47)
  padding: 50px 20px 20px 20px
  +media-breakpoint-up(sm)
    padding: 50px
  .footer-brand
    font-size: 18px
    margin-bottom: 10px
  .footer-text
    font-size: 14px
    color: #7f7f7f
    margin-bottom: 30px
    +media-breakpoint-up(sm)
      margin-bottom: 0
    a
      color: #7f7f7f
