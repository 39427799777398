.services-container

  margin-top: 60px
  margin-bottom: 60px

  +media-breakpoint-up(sm)
    margin-top: 120px
    margin-bottom: 120px

  .services-title
    font-size: 22px

  .services-description
    width: 100%
    padding: 20px
    p
      margin-bottom: 5px

  .icon
    font-size: 60px
    +media-breakpoint-up(sm)
      font-size: 80px

.services-container-service
  margin-bottom: 40px
  +media-breakpoint-up(sm)
    margin-bottom: 0

.factory-container

  margin-top: 60px
  margin-bottom: 60px

  h2
    margin-bottom: 70px
    font-size: 25px
    +media-breakpoint-up(sm)
      font-size: 35px

  img
    width: 100%
    margin-bottom: 20px

  p
    font-size: 20px

  +media-breakpoint-up(sm)
    margin-top: 120px
    margin-bottom: 120px
    img
      margin-bottom: 0
