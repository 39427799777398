.speciality-container
  position: relative

.speciality-image
  display: block
  width: 100%
  height: auto

.speciality-overlay
  position: absolute
  bottom: 0
  left: 0
  right: 0
  height: 100px
  width: 100%
  transition: .5s ease
  opacity: 0.7
  background-color: rgb(0, 0, 0)
  z-index: 8

.speciality-container:hover .speciality-overlay
  height: 100%
  .speciality-overlay-text-description
    visibility: visible
    opacity: 1
    position: relative

.speciality-overlay-text
  color: white
  opacity: 1
  z-index: 10
  font-size: 20px
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  text-align: center
  width: 100%
  padding: 40px

.speciality-overlay-text-title
  margin-top: 10px
  margin-bottom: 10px
  font-size: 1.5rem
  +media-breakpoint-up(sm)
    font-size: 1.9rem

.speciality-overlay-text-description
  padding-top: 20px
  visibility: hidden
  position: absolute
  opacity: 0
  transition: .5s ease
