@font-face {
  font-family: "Flaticon";
  src: url("application/styles/base/fonts/Flaticon.eot");
  src: url("application/styles/base/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("application/styles/base/fonts/Flaticon.woff") format("woff"),
       url("application/styles/base/fonts/Flaticon.ttf") format("truetype"),
       url("application/styles/base/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

// @media screen and (-webkit-min-device-pixel-ratio:0) {
//   @font-face {
//     font-family: "Flaticon";
//     src: url("~styles/base/fonts/Flaticon.svg#Flaticon") format("svg");
//   }
// }


[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-heart:before { content: "\f100"; }
.flaticon-birthday:before { content: "\f101"; }
.flaticon-christmas:before { content: "\f102"; }
.flaticon-catholic:before { content: "\f103"; }
.flaticon-leaf:before { content: "\f104"; }
.flaticon-cap:before { content: "\f105"; }
.flaticon-son:before { content: "\f106"; }
.flaticon-mother:before { content: "\f107"; }
.flaticon-easter:before { content: "\f108"; }
